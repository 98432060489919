export const copyToClipboard = async (link: string) => {
    try {
        if (!navigator.clipboard) {
            throw 'Clipboard is undefined';
        }

        await navigator.clipboard.writeText(link);
    } catch {
        // fallback to deprecated method for writing to clipboard
        const textField = document.createElement('textarea');
        textField.innerText = link;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }
};
