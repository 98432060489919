import React, { useEffect, useState } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { Icon } from '@src/components/Icons/Icons';

ReactModal.setAppElement('body');

interface ModalProps extends ReactModalProps {
    className?: string;
    isOpen: boolean;
    onModalClose?: () => void;
    onModalOpen?: () => void;
    closeButton?: boolean;
    overlayClick?: boolean;
    autoClose?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
    className = '',
    children,
    isOpen,
    onModalClose,
    onModalOpen,
    autoClose,
    closeButton = true,
    overlayClick
}: ModalProps) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (autoClose && active) {
            timeout = setTimeout(() => {
                handleModalClose();
            }, 3500);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [autoClose, active]);

    useEffect(() => {
        setActive(isOpen);
        if (isOpen) {
            onModalOpen?.();
        }
    }, [isOpen]);

    const handleModalClose = () => {
        onModalClose?.();
        setActive(false);
    };

    return (
        <ReactModal
            overlayClassName="fixed bg-black/[.7] inset-0 z-[1000]"
            className={`bg-white w-11/12 md:w-[650px] max-h-screen h-fit absolute m-auto inset-0 flex flex-col p-s md:pb-m md:px-m ${className}`}
            isOpen={active}
            onRequestClose={handleModalClose}
            shouldCloseOnOverlayClick={overlayClick ?? closeButton}
        >
            <div className="flex justify-end">
                {closeButton && (
                    <button
                        type="button"
                        onClick={handleModalClose}
                        id="close-modal"
                        data-testid="close-modal"
                    >
                        <span className="sr-only">close</span>
                        <Icon id="close" />
                    </button>
                )}
            </div>
            <div className="overflow-auto">{children}</div>
        </ReactModal>
    );
};
