import { domain, headers } from '../fetchOptions';

interface PostReturnProps {
    submit: <TBody>(
        url: string,
        body: TBody | Record<string, string>
    ) => Promise<SubmitReturnProps>;
}

export type SubmitReturnProps = {
    data: any;
    statusText: string;
    error: boolean;
};

/**
 * usePostHandler is a different implementation than usePost
 * Use it if you need to check for:
 * - specific HTTP status codes
 * - server response messages on all status codes
 * - handle custom server responses
 */

export const usePostHandler = (): PostReturnProps => {
    const parseResponse = async (response: Response) => ({
        data: await response.json(),
        error: !response.ok,
        statusText: response.statusText
    });

    let result = {
        data: {},
        error: false,
        statusText: ''
    };

    const submit = async <TBody>(
        url: string,
        body: TBody | Record<string, string>
    ): Promise<SubmitReturnProps> => {
        try {
            await fetch(`${domain + url}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            }).then(async (response) => {
                result = await parseResponse(response);
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return result;
            });
            return result;
        } catch {
            return result;
        }
    };

    return {
        submit
    };
};
