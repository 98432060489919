import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { deleteFriend } from '@src/services/data/deleteFriends.data';
import { getFriendVisitLink } from '@src/services/data/getFriendVisitLink.data';
import { copyToClipboard } from '@src/services/utilities/clipboard.service';
import { canShare, share } from '@src/services/utilities/share.service';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import DOMPurify from 'isomorphic-dompurify';
import { useSWRConfig } from 'swr';

import { FriendDataProps } from 'types/friends.types';

interface InviteFriendPopupProps {
    activeFriend: FriendDataProps;
    setActiveFriend: React.Dispatch<any>;
    onFriendDeleted?: () => void;
}

export const InviteFriendModal = ({
    activeFriend,
    setActiveFriend,
    onFriendDeleted
}: InviteFriendPopupProps) => {
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [is401, setIs401] = useState(false);

    const { mutate } = useSWRConfig();

    const inviteFriendPopupText = () => {
        return t('friends.invite-modal.share.text');
    };

    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const [isInvitingFriend, setIsInvitingFriend] = useState(false);
    const onInviteFriend = async () => {
        setError(false);
        setIs401(false);
        setIsInvitingFriend(true);

        try {
            await awaitSpin(async () => {
                const visitLink = await getFriendVisitLink(activeFriend.id_g);
                if (canShare()) {
                    share({
                        title: t('230'),
                        text: t('friends.share-text'),
                        url: visitLink
                    });
                } else {
                    await copyToClipboard(visitLink);
                }
            });
        } catch (e) {
            if (e instanceof Error && e.message.includes('401')) setIs401(true);
            setError(true);
        } finally {
            setIsInvitingFriend(false);
        }
    };

    const [isDeletingFriend, setIsDeletingFriend] = useState(false);
    const [friendDeletionSuccess, setFriendDeletionSuccess] = useState(false);
    const onDeleteFriend = async () => {
        setError(false);
        setIs401(false);
        setIsDeletingFriend(true);
        try {
            await awaitSpin(async () => {
                await deleteFriend(activeFriend);
                await mutate('/friends/get-friends');
            });
            setFriendDeletionSuccess(true);
            onFriendDeleted?.();
        } catch {
            setError(true);
        } finally {
            setIsDeletingFriend(false);
        }
    };

    return (
        <Modal isOpen={!!activeFriend} onModalClose={() => setActiveFriend(null)}>
            <Heading size={6} className="text-center mb-s">
                {activeFriend.name_g}
            </Heading>
            <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inviteFriendPopupText()) }}
            />

            <Button
                data-cy="invite-friend-button"
                text={t('friends.invite-modal.share.button')}
                disabled={isLoading}
                loading={isLoading && isInvitingFriend}
                onClick={onInviteFriend}
                className="mt-xs"
            />

            <Button
                /** delete */
                theme="outline"
                data-cy="delete-friend"
                color="anthracite"
                text={friendDeletionSuccess ? t('242') : t('259')}
                icon={friendDeletionSuccess ? 'tick' : ''}
                disabled={isLoading || friendDeletionSuccess}
                loading={isLoading && isDeletingFriend}
                onClick={onDeleteFriend}
                className="mt-xs"
            />

            {/* back */}
            <Button
                color="naked"
                onClick={() => setActiveFriend(null)}
                text={t('162')}
                className="mt-xs"
                data-cy="go-back-button"
            />

            {error && (
                <Text size={2} className="text-red mt-xs">
                    {is401 ? t('error.401') : `${t('238')} ${t('257')}`}
                </Text>
            )}
        </Modal>
    );
};
