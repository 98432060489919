import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@basic-fit/design-system';
import { Icon } from '@src/components/Icons/Icons';
import { CountryName, getCountryNameByLocale } from '@src/utils/helpers/countryHelpers';

type FormattedUSPProps = {
    id: string;
    size: 1 | 2;
    info?: boolean;
    action?: () => void;
    icon?: string;
    country: CountryName;
};

export const FormattedUSP = ({
    action,
    icon = 'tick',
    id,
    info,
    size,
    country
}: FormattedUSPProps) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    let amountOfClubs = 0;
    try {
        // This way we can dynamically change the amount of clubs in the translation
        // NOTE: This is a temporary solution, we should use a better way to do this

        amountOfClubs = JSON.parse(t('total.clubs.by.country'))[country] ?? 0;
    } catch {
        /** Unable to parse JSON, Use the default */
    }

    let translation = t(id);
    if (id === 'usp.comfort.clubs') {
        translation = translation.replace('{{clubs}}', String(amountOfClubs));
        translation = translation.replace('{{country}}', getCountryNameByLocale(country, language));
    }

    return (
        <div className="flex items-center basis-1/2 mb-xxs">
            <Icon id={icon} className="self-start text-orange mt-[4px] mr-xs min-w-[16px]" />
            <Text data-translation-key={id} size={size}>
                {translation}
            </Text>
            {info && action && (
                <Icon id="question" className="mx-xxs cursor-pointer" onClick={action} />
            )}
        </div>
    );
};
