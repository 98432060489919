import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Text } from '@basic-fit/design-system';
import { ButtonLink } from '@src/components/Buttons/ButtonLink';
import { ChangeMembership } from '@src/components/ChangeMembership/ChangeMembership';
import { FAQ } from '@src/components/FAQ/FAQ.component';
import { Header } from '@src/components/Header/Header';
import { InformationBlock } from '@src/components/InformationBlock/InformationBlock';
import { Section } from '@src/components/Layout/Section';
import { Wrapper } from '@src/components/Layout/Wrapper';
import { UnableToChangeMembershipModal } from '@src/pages/ChangeMembership/components/UnableToChangeMembershipModal';
import { AddOns } from '@src/pages/Membership/Membership/components/AddOns/AddOns';
import { Compensations } from '@src/pages/Membership/Membership/components/Compensation/Compensations';
import { DownloadContract } from '@src/pages/Membership/Membership/components/DownloadContract/DownloadContract';
import { MembershipDetails } from '@src/pages/Membership/Membership/components/MembershipDetails/MembershipDetails';
import { SecondaryMember } from '@src/pages/Membership/Membership/components/SecondaryMember/SecondaryMember';
import { ReactComponent as HeaderImage } from '@src/pages/Membership/Membership/images/header.svg';
import { isMemberAllowedToFreeze } from '@src/services/freeze.service';
import { useMember } from '@src/services/member/MemberProvider';
import { canUseSecondaryMemberFeature } from '@src/services/secondaryMember.service';
import { isMobile } from '@src/utils/helpers/device';
import { canRenewMembership, hasMembership, isEmployee } from '@src/utils/helpers/memberHelpers';
import { useArticles } from '@src/utils/hooks/api/useArticles';
import { useMBFFeatures } from '@src/utils/hooks/api/useMBFFeatures';

import FrozenOption from 'components/partials/FrozenOption';
import ChosenCompensations from '../../../../components/compensation/ChosenCompensations';
import { ChangeAccess } from './components/ChangeAccess/ChangeAccess';
import { FreezeMembershipComponent } from './components/FreezeMembership/FreezeMembershipComponent';
import { ChangeMembershipModal } from './components/Modals/ChangeMembership.modal';
import { RenewMembershipModal } from './components/Modals/RenewMembership.modal';
import { PrepaidRenewalSection } from './components/PrepaidRenewalSection/PrepaidRenewalSection';

export const Membership = () => {
    const { t } = useTranslation();
    const { changeDevice, prepaidRenewal } = useMBFFeatures();
    const { articles } = useArticles();

    const [currentMemberArticles, setCurrentMemberArticles] = useState<string[]>();

    const { state: member } = useMember();

    const isMemberFrozen = member?.peopleMembership?.membershipStatus === 'Frozen';

    const allowFreezeToggle = member?.freeze?.allowFreeze;

    useEffect(() => {
        if (articles) {
            setCurrentMemberArticles(
                articles.boughtArticles?.map((article: any) => article.articleId)
            );
        }
    }, [articles]);

    const Image = () => {
        return (
            <div className="absolute top-0 right-0">
                <HeaderImage />
            </div>
        );
    };

    const [isChangeMembershipModalOpen, setIsChangeMembershipModalOpen] = useState(false);
    const { state: navState } = useLocation();

    useEffect(() => {
        // handle edge cases where navState is not an object
        try {
            // Member tried to access change-membership page directly and was redirected to here
            if (navState?.changeMembershipGuard) {
                setIsChangeMembershipModalOpen(true);
            }
        } catch {
            return;
        }
    }, [navState]);

    const frozenInformationBlock = (
        <InformationBlock>
            <Text size={2}>{t('freeze.infoblock.text')}</Text>

            <ButtonLink to="/membership/freeze">{t('freeze.infoblock.button')}</ButtonLink>
        </InformationBlock>
    );

    return (
        <>
            <Header title={t('40')} text={t('96')} image={<Image />} />
            {isMemberFrozen && (
                <>
                    {isMobile() ? (
                        <Section className="mt-4">
                            <Wrapper>{frozenInformationBlock}</Wrapper>
                        </Section>
                    ) : (
                        <div className="mt-[60px]">{frozenInformationBlock}</div>
                    )}
                </>
            )}

            {prepaidRenewal && canRenewMembership(member) && <PrepaidRenewalSection />}

            {!(member.isCompanyFitness || member.isEmployee) && <Compensations />}

            {member.chosenCompensations && member.chosenCompensations.length > 0 && (
                <ChosenCompensations />
            )}

            {hasMembership(member) && (
                <Section>
                    <MembershipDetails />
                </Section>
            )}

            {changeDevice &&
                member.accessType &&
                hasMembership(member) &&
                canUseSecondaryMemberFeature(currentMemberArticles) && (
                    <Section>
                        <SecondaryMember />
                    </Section>
                )}

            {changeDevice && member.accessType && hasMembership(member) && (
                <Section>
                    <ChangeAccess />
                </Section>
            )}

            {!hasMembership(member) && <Text size={2}>{t('451')}</Text>}

            {/** This is the Covid-19 Freeze contract option */}
            {!isEmployee(member) && <FrozenOption />}

            <DownloadContract />
            <ChangeMembership member={member} />

            {isMemberAllowedToFreeze(member, articles?.boughtArticles) && allowFreezeToggle && (
                <FreezeMembershipComponent data-cy="freeze-membership-button" />
            )}

            {/* addons */}
            {hasMembership(member) && !isEmployee(member) && !isMemberFrozen && <AddOns />}

            <Section>
                <FAQ category="membership" />
            </Section>
            <ChangeMembershipModal />
            <RenewMembershipModal />
            {isChangeMembershipModalOpen && (
                <UnableToChangeMembershipModal
                    isOpen={isChangeMembershipModalOpen}
                    onClose={() => setIsChangeMembershipModalOpen(false)}
                    reason={navState?.changeMembershipGuard}
                />
            )}
        </>
    );
};
