import React, { createContext, useReducer } from 'react';
import type {
    CancellationDateProps,
    CancellationReasonProps
} from '@src/pages/CancelMembership/CancelMembership.types';
import {
    getFromSessionCache,
    removeFromSessionCache,
    setToSessionCache
} from '@src/services/utilities/sessionCache.service';

type State = {
    chosenReason: CancellationReasonProps | null;
    reasonDescription: string;
    chosenDate: CancellationDateProps | null;
};

const enum ActionType {
    SetChosenReason = 'SET_CHOSEN_REASON',
    SetReasonDescription = 'SET_REASON_DESCRIPTION',
    SetChosenDate = 'SET_CHOSEN_DATE',
    Reset = 'RESET'
}

type Action =
    | { type: ActionType.SetChosenReason; payload: CancellationReasonProps }
    | { type: ActionType.SetReasonDescription; payload: string }
    | { type: ActionType.SetChosenDate; payload: CancellationDateProps }
    | { type: ActionType.Reset };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.SetChosenReason:
            setToSessionCache('_chosenReasonToCancel', action.payload);
            return {
                ...state,
                chosenReason: action.payload
            };
        case ActionType.SetReasonDescription:
            setToSessionCache('_reasonDescription', action.payload);
            return {
                ...state,
                reasonDescription: action.payload
            };
        case ActionType.SetChosenDate:
            setToSessionCache('_chosenDateToCancel', action.payload);
            return {
                ...state,
                chosenDate: action.payload
            };
        case ActionType.Reset:
            removeFromSessionCache('_chosenReasonToCancel');
            removeFromSessionCache('_reasonDescription');
            removeFromSessionCache('_chosenDateToCancel');
            return { chosenReason: null, reasonDescription: '', chosenDate: null };

        default:
            return state;
    }
}

function stateInitializer() {
    const chosenReasonCache = getFromSessionCache('_chosenReasonToCancel');
    const chosenReason = tryParse<CancellationReasonProps>(chosenReasonCache);

    const reasonDescriptionCache = getFromSessionCache('_reasonDescription');
    const reasonDescription = tryParse<string>(reasonDescriptionCache) ?? '';

    const chosenDateCache = getFromSessionCache('_chosenDateToCancel');
    const chosenDate = tryParse<CancellationDateProps>(chosenDateCache);

    return { chosenReason, reasonDescription, chosenDate };
}

interface CancellationContextProps extends State {
    setChosenReason(chosenReason: CancellationReasonProps): void;
    setReasonDescription(description: string): void;
    setChosenDate(choseDate: CancellationDateProps): void;
    resetCancelMembershipCache(): void;
}

export const CancellationContext = createContext<CancellationContextProps | null>(null);

function tryParse<T>(value: string | null): T | null {
    if (!value) return null;

    try {
        return JSON.parse(value);
    } catch {
        return null;
    }
}

export const CancelMembershipProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, undefined, stateInitializer);

    function setChosenDate(date: CancellationDateProps) {
        dispatch({ type: ActionType.SetChosenDate, payload: date });
    }

    function setReasonDescription(description: string) {
        dispatch({ type: ActionType.SetReasonDescription, payload: description });
    }

    function setChosenReason(reason: CancellationReasonProps) {
        dispatch({ type: ActionType.SetChosenReason, payload: reason });
    }
    function resetCancelMembershipCache() {
        dispatch({ type: ActionType.Reset });
    }

    return (
        <CancellationContext.Provider
            value={{
                chosenDate: state.chosenDate,
                setChosenDate,
                reasonDescription: state.reasonDescription,
                setReasonDescription,
                chosenReason: state.chosenReason,
                setChosenReason,
                resetCancelMembershipCache
            }}
        >
            {children}
        </CancellationContext.Provider>
    );
};

export function useCancellationContext() {
    const context = React.useContext(CancellationContext);
    if (!context) {
        throw new Error('useCancellationContext must be used within a CancellationContextProvider');
    }
    return context;
}
