import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { StrokeText } from '@src/components/StrokeText/StrokeText';
import { Text } from '@src/components/Text/Text';
import { sendVerificationTokenMachine } from '@src/pages/MyInformation/sendVerificationTokenMachine';
import { useMachine } from '@xstate/react';
import DOMPurify from 'isomorphic-dompurify';
import { assign, createMachine } from 'xstate';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';

type ResendMachineContext = {
    resendError: string | null;
    email: string;
    iban?: string;
    emailTokenType: string;
    password?: string;
    accountHolder?: string;
};

const resendTokenMachine = createMachine<ResendMachineContext>({
    id: 'resendToken',
    preserveActionOrder: true,
    predictableActionArguments: true,
    context: {
        iban: undefined,
        email: '',
        emailTokenType: '',
        resendError: null
    },
    initial: 'idle',
    states: {
        idle: {},
        resending: {
            invoke: {
                src: sendVerificationTokenMachine,
                data: (ctx) => ({
                    iban: ctx.iban,
                    email: ctx.email,
                    emailTokenType: ctx.emailTokenType,
                    password: ctx.password,
                    accountHolder: ctx.accountHolder
                }),
                onError: {
                    target: 'error',
                    actions: assign({
                        resendError: (_, ev) => ev.data.message
                    })
                },
                onDone: { target: 'success' }
            }
        },
        success: {},
        error: {}
    },
    on: {
        RESEND: 'resending'
    }
});

type ResendCodeContentModalProps = {
    email: string;
    emailTokenType: string;
    iban?: string;
    password?: string;
    accountHolder?: string;
    onCloseCb: () => void;
    onOpen?: () => void;
};

export const ResendCodeContentModal = ({
    email,
    emailTokenType,
    password,
    accountHolder,
    onCloseCb,
    onOpen,
    iban
}: ResendCodeContentModalProps) => {
    const { t } = useTranslation();
    const [state, send] = useMachine(resendTokenMachine, {
        context: { email, emailTokenType, iban, password, accountHolder }
    });

    const getTokenError = () => {
        if (!state.matches('error')) return null;
        if (!state.context.resendError) return null;
        if (state.context.resendError) googleTagManagerPush.errorEvent('resend error');

        const customerSupportLink = (
            <>
                &nbsp;
                <a
                    className="underline text-red"
                    href={t('customer.support.url')}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="contact customer support"
                    onClick={() => googleTagManagerPush.clickEvent('to CS')}
                >
                    {t('membership.cancellation.contact_support')}
                </a>
            </>
        );

        if (state.context.resendError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_IBAN_INVALID) {
            return (
                <>
                    {t('details.change.email.iban.invalid')}
                    {customerSupportLink}
                </>
            );
        }

        if (
            state.context.resendError ===
            CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED
        ) {
            return (
                <>
                    {t('details.change.email.iban.tooManyAttempts')}
                    {customerSupportLink}
                </>
            );
        }

        return (
            <>
                {t('details.change.email.iban.unexpectedError')}
                {customerSupportLink}
            </>
        );
    };

    const handleResend = () => {
        send('RESEND');
        googleTagManagerPush.clickEvent('confirm send again');
    };

    return (
        <Modal
            isOpen={true}
            onModalClose={onCloseCb}
            autoClose={state.matches('success')}
            onModalOpen={onOpen}
        >
            <div className="space-y-5 break-words" data-cy="change-details-resend-modal">
                <StrokeText
                    className="text-2xl"
                    stroke="first"
                    color="anthracite"
                    text={t('details.change.email.resend.title')}
                />
                <Text
                    size="m"
                    className="leading-[130%]"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            t('details.change.email.resend.description').replace('%%email%%', email)
                        )
                    }}
                />
                <Button
                    text={t('details.change.email.resend.button')}
                    disabled={state.matches('resending')}
                    loading={state.matches('resending')}
                    icon={state.matches('success') ? 'tick' : undefined}
                    onClick={handleResend}
                    data-cy="resend"
                />

                {getTokenError() && (
                    <Text size="s" className="text-red" data-cy="resend-error">
                        {getTokenError()}
                    </Text>
                )}
                <Button
                    theme="solid"
                    color="naked"
                    text={t('details.change.email.resend.cancel')}
                    onClick={onCloseCb}
                    data-cy="close-resend-modal"
                />
            </div>
        </Modal>
    );
};
