import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Icon, Text } from '@basic-fit/design-system';
import { Button } from '@src/components/Buttons/Button';
import { Modal } from '@src/components/Modals/Modal';
import { Table } from '@src/components/Table/Table';
import { TableFlex } from '@src/components/TableFlex/TableFlex';
import { Price } from '@src/pages/ChangeMembership/components/Price';
import { isFreezeExtra } from '@src/pages/ChangeMembership/utils';
import { useMember } from '@src/services/member/MemberProvider';
import {
    getArticleIntervalText,
    hasEndDate,
    isActive,
    isOneTimeArticle
} from '@src/utils/helpers/articleHelpers';
import { getPrice } from '@src/utils/helpers/priceHelpers';
import { redirect } from '@src/utils/helpers/redirect';
import { Article } from '@src/utils/hooks/api/useArticles';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import DOMPurify from 'isomorphic-dompurify';

import { getArticleOrderLink } from 'services/API/get-order-link';
import removeAddon from '../../../../../../services/API/remove-article';
import { toLocal } from '../../../../../../services/date-converter';
import googleTagManagerPush from '../../../../../../services/google-tag-manager-push';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    article: Article;
    articleBought: boolean;
    membershipId: string;
    lang: string;
};

export const AddOnsModal = ({ isOpen, onClose, article, articleBought, lang }: ModalProps) => {
    const { t } = useTranslation();
    const { awaiting: isLoading, awaitSpin } = useAwaitSpinner();
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setDisabled(false);
        setSuccess(false);
        setError(false);
    }, [onClose]);

    const ModalButton = ({ text, onClick }: { text: string; onClick: () => Promise<void> }) => {
        return success ? (
            <Button
                text={t('242')}
                onClick={onClick}
                loading={isLoading}
                disabled={true}
                icon="tick"
            />
        ) : (
            <Button
                text={text}
                onClick={onClick}
                loading={isLoading}
                disabled={disabled}
                data-testid="confirm-button"
            />
        );
    };

    const AlreadyOrdered = () => {
        return (
            <>
                <Text size={1} className="my-m">
                    {t('1949')
                        ?.replace(/{articleName}/g, article.articleShortDescription)
                        .replace(/{startDate}/g, toLocal(article.peopleMembershipArticleStartdate))}
                </Text>
                <ModalButton text={t('356')} onClick={activateAddOn} />
            </>
        );
    };

    const CancelActive = () => {
        return isFreezeExtra(article) ? (
            <CancelFreeze article={article} />
        ) : (
            <>
                <Text size={1} className="my-m">
                    {t('538')}
                </Text>
                <ModalButton text={t('352')} onClick={cancelAddOn} />
            </>
        );
    };

    const AwaitingWithoutDate = () => {
        return (
            <Text size={1} className="my-m">
                {t('536')}
            </Text>
        );
    };

    const AwaitingWithDate = () => {
        return (
            <Text size={1} className="my-m">
                {`${t('350')} ${toLocal(article.peopleMembershipArticleEnddate)}`}
            </Text>
        );
    };

    const OneTimeOrder = () => {
        return (
            <>
                <div className="my-m">
                    <Table
                        size="m"
                        data={[
                            [
                                t('article-periodic.purchase-modal.price'),
                                getPrice(article.articlePrice, lang)
                            ]
                        ]}
                    />
                </div>
                <ModalButton text={t('356')} onClick={activateAddOn} />
            </>
        );
    };

    const PeriodicOrder = () => {
        return (
            <>
                <div className="my-m">
                    <Table
                        size="m"
                        data={[
                            [
                                t('article-periodic.purchase-modal.price'),
                                getPrice(article.articlePrice, lang)
                            ],
                            [
                                t('article-periodic.purchase-modal.next-date'),
                                toLocal(article.firstDirectDebit)
                            ]
                        ]}
                    />
                </div>
                <ModalButton text={t('356')} onClick={activateAddOn} />
            </>
        );
    };

    const CancelFreeze = ({ article }: { article: Article }) => {
        const { state: member } = useMember();

        return (
            <>
                <div className="my-m">
                    <span>{t('freeze.cancel.modal.description')}</span>
                    <TableFlex
                        size="m"
                        data={[
                            [
                                t('membership.endDate'),
                                <span className="font-bold" key="end-date-key">
                                    {toLocal(new Date(member.contract_end_date_g))}
                                </span>
                            ]
                        ]}
                        className="border-b"
                    />
                    <Text className="my-l">
                        <Icon id="info-orange" className="mr-xxs" />
                        <span>{t('freeze.cancel.modal.info')}</span>
                    </Text>
                    {article.originalPrice && (
                        <Price
                            amount={article.originalPrice}
                            caption={`/ ${t(getArticleIntervalText(article, member))}`}
                            className="mt-xxxs"
                        />
                    )}
                </div>
                <ModalButton text={t('freeze.cancel.modal.confirm')} onClick={cancelAddOn} />
            </>
        );
    };

    const ModalTitle = ({ article }: { article: Article }) => {
        return (
            <Heading size={6} className="mb-s">
                {isFreezeExtra(article)
                    ? t('freeze.cancel.modal.title')
                    : isActive(article) && !isOneTimeArticle(article)
                    ? t('352')
                    : `${t('351')}: ${article.articleShortDescription}`}
            </Heading>
        );
    };

    const activateAddOn = async () => {
        try {
            setDisabled(true);
            await awaitSpin(() =>
                getArticleOrderLink(article).then((order) => {
                    redirect(order);
                    return;
                })
            );
            googleTagManagerPush.addAddOn(article.articleDescription);
            setSuccess(true);
        } catch (e) {
            setError(true);
            console.log(e);
        }
        setDisabled(false);
    };

    const cancelAddOn = async () => {
        try {
            setDisabled(true);
            await awaitSpin(() => removeAddon(article));
            googleTagManagerPush.cancelAddOn(article.articleDescription);
            setSuccess(true);
        } catch (e) {
            setError(true);
            console.log(e);
        }
        setDisabled(false);
    };

    return (
        <Modal isOpen={isOpen} onModalClose={onClose}>
            <ModalTitle article={article} />

            {!isFreezeExtra(article) && (
                <p
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t(article.articleLongDescription))
                    }}
                />
            )}

            {!isActive(article) &&
                (hasEndDate(article) ? (
                    <AwaitingWithoutDate />
                ) : isOneTimeArticle(article) ? (
                    <OneTimeOrder />
                ) : (
                    <PeriodicOrder />
                ))}

            {isActive(article) && hasEndDate(article) ? <AwaitingWithDate /> : null}

            {articleBought && isOneTimeArticle(article) ? (
                <AlreadyOrdered />
            ) : (
                isActive(article) && !hasEndDate(article) && <CancelActive />
            )}

            {error && (
                <Text size={2} className="text-orange mt-xs">{`${t('238')} ${t('257')}`}</Text>
            )}
        </Modal>
    );
};
