import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@src/components/Buttons/Button';
import { Form } from '@src/components/Form/Form';
import { TextInput } from '@src/components/Form/TextInput/TextInput';
import { Text } from '@src/components/Text/Text';
import { ResendCodeContentModal } from '@src/feature/ChangeMemberModals/Resend.modal';
import { SuccessModal } from '@src/feature/ChangeMemberModals/Success.modal';
import { TooManyTriesModal } from '@src/feature/ChangeMemberModals/TooManyTries.modal';

import googleTagManagerPush from 'services/google-tag-manager-push';
import { CHANGE_MEMBER_ERROR_MESSAGE_MAP } from 'types/change-member-response';
import { ModalState } from '../machine-helpers';
import { ChangeEmailContext } from './changeEmail.machine';

export const VerifyCode = () => {
    const { t } = useTranslation();
    const [state, send] = ChangeEmailContext.useActor();

    const effectRan = useRef(false);

    useEffect(() => {
        if (!effectRan.current) {
            googleTagManagerPush.viewPageEvent('view verification');
        }
        effectRan.current = true;
    }, []);

    const codeError = ChangeEmailContext.useSelector((state) => {
        if (!state.context.codeError) return undefined;
        if (!state.matches('code.error')) return undefined;

        if (
            state.context.codeError ===
            CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_EMAIL_TOKEN_PASSED_INVALID
        ) {
            return t('details.change.email.code.error.noMatch');
        }
        if (
            state.context.codeError === CHANGE_MEMBER_ERROR_MESSAGE_MAP.DETAILS_RATE_LIMIT_EXCEEDED
        ) {
            // NOTE: rate limit error is handled in the modal
            return undefined;
        }

        return (
            <>
                {t('details.change.email.code.error.unexpectedError')}
                &nbsp;
                <a
                    className="underline text-red"
                    href={t('customer.support.url')}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="contact customer support"
                    onClick={() => googleTagManagerPush.clickEvent('to CS')}
                >
                    {t('membership.cancellation.contact_support')}
                </a>
            </>
        );
    });

    const shouldShowResend = ChangeEmailContext.useSelector((state) => {
        return state.context.modal === ModalState.RESEND;
    });
    const shouldShowTooManyTries = ChangeEmailContext.useSelector((state) => {
        return state.context.modal === ModalState.TOO_MANY_TRIES;
    });
    const shouldShowSuccess = ChangeEmailContext.useSelector((state) => {
        return state.context.modal === ModalState.SUCCESS;
    });

    function closeModal() {
        send('CLOSE_MODAL');
        googleTagManagerPush.clickEvent('close modal');
    }

    return (
        <div data-cy="change-email-code">
            <Form
                onSubmit={({ code }) => send({ type: 'SUBMIT_CODE', code })}
                name="verifyCode"
                className="space-y-5"
            >
                <TextInput
                    required
                    name="code"
                    type="number"
                    maxLength={6}
                    minLength={6}
                    label={t('details.change.email.code.label')}
                    error={codeError}
                    onInput={() => send('EDITING')}
                    data-cy="code"
                    autoComplete="one-time-code"
                />
                <div className="flex flex-row">
                    <Text size="s" className="text-anthracite">
                        {t('details.change.email.code.help')}
                        <button
                            type="button"
                            className="underline"
                            onClick={(e) => {
                                e.preventDefault();
                                send({ type: 'OPEN_MODAL', modal: ModalState.RESEND });
                                googleTagManagerPush.clickEvent('send verification again');
                            }}
                            data-cy="resend-code"
                        >
                            {t('details.change.email.code.resend')}
                        </button>
                    </Text>
                </div>
                <Button
                    type="submit"
                    text={t('128')}
                    loading={state.matches('code.verifying')}
                    disabled={state.matches('code.verifying')}
                    data-cy="submit-code"
                    onClick={() => googleTagManagerPush.clickEvent('confirm verification')}
                />
                <Button
                    onClick={() => send('BACK')}
                    className="!bg-white hover:bg-white !text-anthracite"
                    text={t('162')}
                    data-cy="back"
                />
            </Form>
            {shouldShowResend && (
                <ResendCodeContentModal
                    email={state.context.newEmail}
                    emailTokenType={state.context.flow}
                    password={state.context.password}
                    onCloseCb={closeModal}
                    onOpen={() => googleTagManagerPush.viewPageEvent('resend again modal')}
                />
            )}
            {shouldShowTooManyTries && (
                <TooManyTriesModal
                    onCloseCb={closeModal}
                    onOpen={() => googleTagManagerPush.viewPageEvent('too many tries modal')}
                />
            )}
            {shouldShowSuccess && (
                <SuccessModal
                    onCloseCb={closeModal}
                    autoClose
                    onOpen={() => googleTagManagerPush.viewPageEvent('success modal email')}
                />
            )}
        </div>
    );
};
