/**
 * Convert string to type
 * Example: /foo?val=null always returns key/value as string, parseString(val) will return a primitive type 'null'
 */
export const parseString = (value?: string | null) => {
    try {
        return JSON.parse(<string>value);
    } catch {
        return value;
    }
};

export const replacePlaceholder = (text: string, placeholder: string, value: string) => {
    return text.replace(placeholder, value);
};
